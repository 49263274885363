body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Roboto-Regular - latin */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
    url('./assets/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
    url('./assets/fonts/Roboto/Roboto-Regular.eot') format('embedded-opentype'),
    url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('ttf');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Roboto-500 - latin */

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('./assets/fonts/Roboto/Roboto-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./assets/fonts/Roboto/Roboto-500.woff') format('woff');
}

/* Lato-latin */

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'),
    url('./assets/fonts/Lato/Lato-Regular.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-Regular.eot') format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-Regular.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Lato Bold'), local('Lato-Bold'),
    url('./assets/fonts/Lato/Lato-700.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-700.eot') format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-700.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Lato Black'), local('Lato-Black'),
    url('./assets/fonts/Lato/Lato-900.woff2') format('woff2'),
    url('./assets/fonts/Lato/Lato-900.eot') format('embedded-opentype'),
    url('./assets/fonts/Lato/Lato-900.woff') format('woff');
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Jost'), local('Jost-Regular'), local('Jost-400'),
    url('./assets/fonts/Jost/Jost-400.woff2') format('woff2'),
    url('./assets/fonts/Jost/Jost-400.woff') format('woff'),
    url('./assets/fonts/Jost/Jost-400.ttf') format('ttf'),
    url('./assets/fonts/Jost/Jost-400.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/Jost/Jost-400.svg#Jost') format('svg');
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Jost-Medium'), local('Jost-500'),
    url('./assets/fonts/Jost/Jost-500.woff2') format('woff2'),
    url('./assets/fonts/Jost/Jost-500.woff') format('woff'),
    url('./assets/fonts/Jost/Jost-500.ttf') format('ttf'),
    url('./assets/fonts/Jost/Jost-500.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/Jost/Jost-500.svg#Jost') format('svg');
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Jost-Bold'), local('Jost-700'),
    url('./assets/fonts/Jost/Jost-700.woff2') format('woff2'),
    url('./assets/fonts/Jost/Jost-700.woff') format('woff'),
    url('./assets/fonts/Jost/Jost-700.ttf') format('ttf'),
    url('./assets/fonts/Jost/Jost-700.eot?iefix') format('embedded-opentype'),
    url('./assets/fonts/Jost/Jost-700.svg#Jost') format('svg');
}

@font-face {
  font-family: 'Cookie';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Cookie'), local('Cookie-Regular'), local('Cookie-400'),
    url('./assets/fonts/Cookie/Cookie-400.woff2') format('woff2'),
    url('./assets/fonts/Cookie/Cookie-400.woff') format('woff'),
    url('./assets/fonts/Cookie/Cookie-400.eot?iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Cookie/Cookie-400.ttf') format('ttf');
}

@font-face {
  font-family: 'Handlee';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Handlee'), local('Handlee-Regular'), local('Handlee-400'),
    url('./assets/fonts/Handlee/Handlee-400.woff2') format('woff2'),
    url('./assets/fonts/Handlee/Handlee-400.woff') format('woff'),
    url('./assets/fonts/Handlee/Handlee-400.eot?iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Handlee/Handlee-400.ttf') format('ttf');
}

body {
  margin: 0;
  padding: 0;
}

.nav-style {
  text-decoration: none;
  color: unset;
}

.menu-option-link-style {
  outline: none;
  border: none;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fullCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.relCenter {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lato {
  font-family: Lato, sans-serif;
}

.f-15px {
  font-size: 15px;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 100%;
  text-align: justify;
}

.darkest-red {
  color: #9d0003;
}

.ws-preWrap {
  white-space: pre-wrap;
}

.write-entry-textarea {
  color: unset;
  background: transparent;
}

.link-blue {
  color: cornflowerblue;
}

.bg-img {
  max-height: 85vh;
  opacity: 0.7;
  z-index: -1;
}

/* overflow wrap */
.ow {
  /* overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto; */
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

@media (min-width: 900px) {
  .write-entry-textarea {
    width: 75%;
  }
}

@media (min-width: 1100px) {
  .write-entry-textarea {
    width: 50%;
  }
}

@media (max-width: 900px) {
  .bg-img {
    opacity: 0.5;
  }
}

.m-auto {
  margin: auto;
}
.mt-3 {
  margin-top: -1rem;
}

.ht-fit {
  height: fit-content;
}
.wd-fit {
  width: fit-content;
}

.loading-anim {
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.search-result-content {
  max-height: 2.9em;
  overflow: hidden;
}
